.loginPageParentContainer,
.loginLogoHeaderModule,
.loginModule,
.loginFields {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginPageParentContainer {
  background-color: #0e4362;
  min-height: 100vh;
  height: auto;
  color: #c9c9c9;
}

.loginLogoHeaderModule {
  max-width: 240px;
  margin: 40px 0;
}

.loginLogoHeaderModule > * {
  width: 100%;
  text-align: center;
  font-size: xx-large;
}

.loginLogoImage {
  width: 100%;
}

.projectTitle > * {
  margin: 0;
}

.loginModule {
  max-width: 500px;
}

.loginModule > * {
  margin: 5px 0;
}

.loginFields > * {
  background-color: #0e4362;
  margin: 10px 0;
  padding: 12px;
  outline: 2px solid #c9c9c9;
  color: #c9c9c9;
}

.loginFields > *:focus-visible {
  outline: 2px solid #c9c9c9;
  transform: scale(1.1);
}

.loginBtn {
  background-color: #001e30;
}

#adminUserName::placeholder {
  color: #c9c9c9;
}

#adminPassword::placeholder {
  color: #c9c9c9;
}
