.adminPageParentContainer {
  background-color: #0e4362;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.dashboardContainer {
  background-color: #dde3ea;
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
}
.leftPanelContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 330px;
  max-height: calc(100vh - 60px);
  overflow-y: auto;
  min-width: 100px;
}

.addPatientBtnContainer {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  width: 100%;
}
.addPatientBtnContainer > * {
  background-color: #001e30;
  width: 80%;
  font-size: 20px;
}

.patientListContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.patientListHeading {
  padding-left: 15px;
  font-size: 21px;
  font-weight: 500;
}

.patientContainer > * {
  background-color: #dde3ea;
  width: 100%;
  color: black;
  border-radius: 0;
  text-align: start;
  font-size: 20px;
}
.patientContainer > *:hover {
  background-color: #0e4362;
  color: #c9c9c9;
}

.patientContainer > *:focus {
  outline: 0;
}

.patientContainer > *:focus-visible {
  outline: 0;
}

.rightPanelContainer {
  position: relative;
  background-color: rgb(255, 255, 255);
  margin-left: 330px;
  width: 100%;
  z-index: 0;
}

.logoBackground {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 31%;
  z-index: -1;
}

.logoBackground > img {
  width: 100%;
}
