.patientDetailsContainer {
  display: flex;
  flex-direction: column;
}

.patientDetailsContainer > * {
  margin: 5px 0;
}

.editFormControls {
  display: flex;
}

.formFields {
  max-width: 330px;
  min-width: 330px;
}

.disabled {
  background-color: #d9d9d9;
  outline: 0px;
}
.active {
  background-color: #d9d9d900;
}

.editBtn {
  background-color: #0e4362;
  color: white;
  margin-right: 4px;
}

.editBtn:hover {
  background-color: #001e30;
  color: white;
}

#editBtn:before {
  font-family: FontAwesome;
  content: "\f044";
  padding: 5px;
}

#editSaveBtn{
  display: none;
}
#editSaveBtn:before {
font-family: FontAwesome;
content: "\f0c7";
padding: 5px;
}

#editCancelBtn {
  display: none;
}

#editCancelBtn:before {
  font-family: FontAwesome;
  content: "\f057";
  padding: 4px;
}

.editDeleteControls {
  display: flex;
  margin: 30px 0;
}

.editDeleteControls > button {
  width: 100%;
  color: white;
}

.deleteConfirmationModal {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #0e43623f;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.confirmDialogue {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 40%;
  max-width: 500px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.confirmControls {
  margin: 10px 0 0 0;
}

.confirmControls > button {
  margin: 10px 10px 0 10px;
}
