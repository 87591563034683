body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
}

.componentHeading {
  font-size: 30px;
  font-weight: 500;
}

label {
  font-size: 20px;
}

input {
  border-radius: 5px;
  border-color: #0e4362;
  border-width: 0px;
  border-style: solid;
  background-color: #d9d9d900;
  font-size: 18px;
  height: 30px;
  outline: 1px solid #0e4362;
}

input:focus {
  outline: 1px solid #0e4362;
}

input:focus-visible {
  outline: 1px solid #0e4362;
}

input::placeholder {
  font-family: "Roboto", sans-serif;
  color: #494949;
}

button {
  border: 0;
  border-radius: 5px;
  padding: 10px 25px;
  color: #c9c9c9;
}

button:focus {
  outline: 2px solid #c9c9c9;
}

button:focus-visible {
  outline: 2px solid #c9c9c9;
}

.redClass {
  background-color: #ac1b1b;
}

.blueClass {
  background-color: #0e4362;
}

.selected{
background-color: #001e30;
color: white;
outline: 0px;
}

/* error related css classes */
.errorField {
  outline: 2px solid #ba1a1a;
  border-color: #ba1a1a;
  /* font-family: FontAwesome; */
  /* content: "\f06a"; */
}

.errorMsg {
  display: none;
  color: #ba1a1a;
  font-size: small;
}
