.headerParentContainer,
.logoHeadingModule,
.logoImageContainer {
  display: flex;
}

.headerParentContainer {
  background-color: #0e4362ab;
  color: #c9c9c9;
  position: fixed;
  max-height: 60px;
  height: 60px;
  width: 100%;
}

.logoHeadingModule {
  flex-direction: row;
  align-items: center;
  margin-left: 15px;
}

.logoImageContainer {
  justify-content: center;
  align-items: center;
  max-width: 45px;
}

.logoImage {
  width: 100%;
}

.headingContainer {
  margin-left: 10px;
  font-family: "Kanit", sans-serif;
  font-size: large;
  font-weight: 400;
}
