@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);

.patientDashboardParentContainer {
  display: flex;
  width: 100%;
  z-index: 1;
}

.patientDashboardContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 40px;
}

.patientDashboardContainer > * {
  margin: 5px 0;
  width: 330px;
}

.selectedPatient {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 2px solid black;
  margin-bottom: 10px;
}

#exportBtn {
  background-color: #001e30;
  color: white;
}

#exportBtn:before {
  font-family: FontAwesome;
  content: "\f0ed";
  padding: 4px;
}

.patientTabsContainer {
  display: flex;
  flex-direction: row;
}

.trackersAndDetailsContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.trackersContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.tracker {
  background-color: rgba(255, 255, 255, 0.712);
  display: flex;
  flex-direction: column;
  min-width: 50%;
  margin: 0px 0px 30px 0px;
}

.trackerHeader {
  width: 100%;
}

.trackerBody {
  display: flex;
  width: 100%;
}
.trackerBody > * {
  width: 100%;
}

.tabButton {
  border-radius: 0%;
  border-bottom: 2px solid black;
  background-color: white;
  color: black;
}

.tabButton:focus {
  outline: 0px;
}

.tabButton:hover {
  background-color: #0e4362;
  color: white;
}

#graphButton:before {
  font-family: FontAwesome;
  content: "\f1fe";
  padding: 4px;
}

#detailsButton:before {
  font-family: FontAwesome;
  content: "\f0f6";
  padding: 4px;
}
