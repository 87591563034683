.addPatientPageParentContainer {
  display: flex;
  width: 100%;
}

.addPatientForm,
.formFields {
  display: flex;
  flex-direction: column;
}

.addPatientForm {
  width: 700px;
  margin: 10px 40px;
}

.addPatientForm > * {
  margin: 5px 0;
  width: 330px;
}

.addPatientPageHeading {
  width: 100%;
  border-bottom: 2px solid black;
  margin-bottom: 10px;
}

.formFields > * {
  margin: 2px 0;
}

.formFields > label{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.formControls {
  width: 100%;
}

.formControls > button {
  background-color: #001e30;
  font-size: 20px;
  margin: 20px 0;
}

#clearBtn {
  margin: 0 20px;
}

.errorplaceholder::placeholder {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: red;
}

.icon:before {
  position: relative;
  right: -94%;
  top: -30px;
  font-family: FontAwesome;
  content: "\f06a";
  color: red;
  /* visibility: hidden; */
}
